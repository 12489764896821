import "./src/styles/global.css"
import Cookies from 'universal-cookie';

export const onClientEntry = () => {
  // disable tracking by default
  window[`ga-disable-${process.env.GA_TRACKING_ID}`] = true;

  const cookies = new Cookies();
  const cookieConsent = cookies.get('jendriksworld_analytics_consent');

  // renew expiration
  if (cookieConsent !== undefined) {
    window[`ga-disable-${process.env.GA_TRACKING_ID}`] = (cookieConsent === 'false');
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    cookies.set('jendriksworld_analytics_consent', cookieConsent, {
      expires: oneYearFromNow
    });
  }
}
